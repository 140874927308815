import { Language } from 'types/Langauge';

export const getLocales = (): Language[] => {
  const allLocales: Language[] = (process.env.REACT_APP_LOCALES as string)
    .split(',')
    .map((language) => {
      const languageInfo: string[] = language.split(':');
      return {
        code: languageInfo[0],
        fullName: languageInfo[1],
      };
    });

  return allLocales;
};
