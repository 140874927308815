import {
  WEB_SOCKET_PING_INTERVAL,
  WEB_SOCKET_PING_MESSAGE,
} from 'config/constants';

export const createWebSocket = (userId: number): WebSocket => {
  const webSocketBaseUrl = process.env.REACT_APP_CHAT_SOCKET_BASE_URL ?? '';

  const webSocket = new WebSocket(`${webSocketBaseUrl}id=${userId}`);

  const interval = setInterval(() => {
    webSocket.send(WEB_SOCKET_PING_MESSAGE);
  }, WEB_SOCKET_PING_INTERVAL);

  webSocket.addEventListener('close', () => {
    clearInterval(interval);
  });

  return webSocket;
};
