import { useTranslation } from 'react-i18next';
import styles from './Footer.module.css';
import classNames from 'classnames';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footerContent}>
        <div className={styles.linksWrapper}>
          <ul>
            <p>{t('company_label')}</p>
            <li>
              <a href="/about-us">{t('about_us_label')}</a>
            </li>
            <li>
              <a href="/contact">{t('contact_label')}</a>
            </li>
          </ul>
          <ul>
            <p>{t('information_label')}</p>
            <li>
              <a href="/terms-and-conditions">
                {t('terms_and_conditions_label')}
              </a>
            </li>
            <li>
              <a href="/privacy-policy">{t('privacy_policy_label')}</a>
            </li>
          </ul>
          <ul>
            <p></p>
            <li>
              <a href="/anti-money-laundering">
                {t('anti_money_laundering_label')}
              </a>
            </li>
            <li>
              <a href="/responsible-gaming">{t('responsible_gaming_label')}</a>
            </li>
          </ul>
          <div className={styles.smallIconsWrapper}>
            <a target="_blank" href="https://www.gamcare.org.uk/">
              <img src="/photos/icons/gamcare.png" alt="GamCare" />
            </a>
            <img src="/photos/icons/18.png" alt="18+" />
          </div>
        </div>
        <div className={styles.paymentIconsWrapper}>
          <a
            className={classNames(styles.paymentCard, styles.mastercard)}
            title="Mastercard"
          />
          <a
            className={classNames(styles.paymentCard, styles.visa)}
            title="Visa"
          />
          <a
            className={classNames(styles.paymentCard, styles.neteller)}
            title="Netteler"
          />
          <a
            className={classNames(styles.paymentCard, styles.paysafecard)}
            title="Paysafecard"
          />
          <a
            className={classNames(styles.paymentCard, styles.skrill)}
            title="Skrill"
          />
        </div>

        <div className={styles.legalInfoWrapper}>
          <div className={styles.verifiedImage}>
            <img src="/photos/gaming-curacao-logo.png" alt="Gaming Curacao" />
            <img src="/photos/icons/verified.png" alt="Verified" />
          </div>
          <p>
            www.tottalo.com is operated by Nigarava NZ, a company registered
            under the laws of Curacao, registered under No. 928546 at Curaçao.
            This website is Licensed and regulated by Gaming Services provider
            N.Z. License no. 225/883.
          </p>
        </div>
        <p>
          Sports betting is associated with problem gambling. Play responsibly.
          As a player, you are required to comply with the norms and laws of
          government institutions and authorities in your country. Tottalo
          cannot, in any way, assume responsibility for unlawful or unauthorized
          use of its contents and services. Minors are prohibited from
          participating in sports betting or other games on this website. All
          information on this website is subject to change at any time without
          prior notice.
        </p>
      </div>
      <div className={styles.copyright}>
        <img
          src="/photos/icons/top-arrow.png"
          alt="Top"
          onClick={() => window.scrollTo(0, 0)}
        />
        © {new Date().getFullYear()} by Tottalo, All Rights Reserved.
      </div>
    </div>
  );
};
