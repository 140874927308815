import { useTranslation } from 'react-i18next';
import styles from '../../styles/pages/NotFound.module.css';
import { Layout } from 'components/Layout/Layout';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { MobileLayout } from 'components/mobile/MobileLayout/MobileLayout';

export const NotFound = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();

  const getContent = () => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>{t('page_not_found_label')}</div>
      </div>
    );
  };

  return (
    <>
      {!isMobile && <Layout>{getContent()}</Layout>}
      {isMobile && <MobileLayout>{getContent()}</MobileLayout>}
    </>
  );
};
