import { Layout } from 'components/Layout/Layout';
import styles from '../styles/pages/WorkInProgress.module.css';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { MobileLayout } from 'components/mobile/MobileLayout/MobileLayout';

export const WorkInProgress = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();

  const getPageContent = () => {
    return (
      <div className={styles.container}>
        {t('work_in_progress_label')}
        <p>{t('come_back_later_label')}</p>
      </div>
    );
  };

  return (
    <>
      {!isMobile && <Layout>{getPageContent()}</Layout>}
      {isMobile && <MobileLayout>{getPageContent()}</MobileLayout>}
    </>
  );
};
