import { Header } from 'components/Header/Header';
import styles from './Layout.module.css';
import { SubHeader } from 'components/SubHeader/SubHeader';
import { Menu } from 'components/Menu/Menu';
import { ReactNode } from 'react';
import { Footer } from 'components/Footer/Footer';

interface Props {
  children?: ReactNode;
  hideSubheader?: boolean;
  hideMenu?: boolean;
  hideFooter?: boolean;
}
export const Layout = ({
  children = <></>,
  hideSubheader = false,
  hideMenu = false,
  hideFooter = false,
}: Props) => {
  return (
    <>
      <Header />
      <div className={styles.pageWrapper}>
        <div className={styles.contentWrapper}>
          {!hideSubheader && <SubHeader />}
          <div
            className={styles.pageContent}
            data-has-subheader={!hideSubheader}
          >
            {!hideMenu && <Menu />}
            {children}
          </div>
        </div>
        {!hideFooter && <Footer />}
      </div>
    </>
  );
};
