import { ACCESS_TOKEN_REFRESH_INTERVAL } from 'config/constants';
import { formatDateTime } from 'helpers/dateHelper';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { accessTokenRefresh, loginApi, logoutApi } from 'services/authService';
import { createWebSocket } from 'services/webSocketService';
import { UserInfo } from 'types/UserInfo';

export const UserContext = React.createContext(null as any);

export default ({ children }: any) => {
  const [loggedUser, setLoggedUser] = useState<UserInfo>(null!);
  const [webSocket, setWebSocket] = useState<WebSocket>(null!);
  const [tokenRefreshInterval, setTokenRefreshInterval] = useState<any>(null);

  useEffect(() => {
    const loggedUserString = sessionStorage.getItem('loggedUser');
    if (loggedUserString) {
      setLoggedUser(JSON.parse(loggedUserString));
    }
  }, []);

  useEffect(() => {
    let user: UserInfo | null = JSON.parse(
      sessionStorage.getItem('loggedUser') ?? 'null'
    );
    if (webSocket) {
      webSocket.addEventListener('message', (e) => {
        const data = JSON.parse(e.data);

        if (user) {
          if (data.type === 'balance') {
            console.log('New balance:', data.balance);
            setLoggedUser((prevUser: UserInfo) => {
              const updatedUser = { ...prevUser };
              updatedUser.balance = data.balance;
              sessionStorage.setItem('loggedUser', JSON.stringify(updatedUser));
              return updatedUser;
            });
          }
        }
      });
    } else {
      if (user) {
        setWebSocket(createWebSocket(user.id));
      }
    }
  }, [webSocket]);

  const login = async (
    username: string,
    password: string
  ): Promise<UserInfo | null> => {
    let loginResult = await loginApi(username, password);
    if (loginResult) {
      loginResult.logged_in_from = formatDateTime(new Date());
      sessionStorage.setItem('loggedUser', JSON.stringify(loginResult));
      setLoggedUser(loginResult);
      setWebSocket(createWebSocket(loginResult.id));

      const interval = setInterval(async () => {
        let user: UserInfo | null = JSON.parse(
          sessionStorage.getItem('loggedUser') ?? 'null'
        );
        if (user) {
          const newAccessToken = await accessTokenRefresh(user);
          console.log('New Access Token:', newAccessToken);
          if (newAccessToken === null) {
            logout();
          } else {
            user.access_token = newAccessToken;
            sessionStorage.setItem('loggedUser', JSON.stringify(user));
            setLoggedUser(user);
          }
        }
      }, ACCESS_TOKEN_REFRESH_INTERVAL);
      setTokenRefreshInterval(interval);
      return loginResult;
    }
    return null;
  };

  const logout = async () => {
    if (await logoutApi(loggedUser)) {
      if (webSocket) {
        webSocket.close();
      }
      sessionStorage.removeItem('loggedUser');
      clearInterval(tokenRefreshInterval);
      setLoggedUser(null!);
    } else {
      alert(t('network_error_message'));
    }
  };

  return (
    <UserContext.Provider value={{ loggedUser, login, logout, webSocket }}>
      {children}
    </UserContext.Provider>
  );
};
