import classNames from 'classnames';
import styles from './CasinoGameList.module.css';
import { CasinoGameListItem } from 'components/CasinoGameListItem/CasinoGameListItem';
import { useContext, useEffect, useRef, useState } from 'react';
import { CasinoGame } from 'types/CasinoGame';
import { UserContext } from 'context/userContext';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'hooks/useBreakpoint';

interface Props {
  gameList: any;
}
export const CasinoGameList = ({ gameList }: Props) => {
  const [gameUrl, setGameUrl] = useState<string>('');
  const [expanded, setExpanded] = useState<boolean[]>([
    true,
    ...Array(gameList.length - 1).fill(false),
  ]);
  const currentGameRef = useRef(null);
  const { loggedUser } = useContext(UserContext);
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    if (!loggedUser) {
      setGameUrl('');
    }
  }, [loggedUser]);

  useEffect(() => {
    if (gameUrl) {
      window.scrollTo(0, 0);
    }

    const messageListener = (event: any) => {
      if (
        event.data === 'closeFrame' ||
        event.data === 'close-frame' ||
        event.message === 'closeFrame' ||
        event.message === 'close-frame'
      ) {
        setGameUrl('');
      }
    };

    window.addEventListener('message', messageListener);

    return () => window.removeEventListener('message', messageListener);
  }, [gameUrl]);

  const changeExpanded = (index: number) => {
    let expandedTemp = [...expanded];
    expandedTemp[index] = !expandedTemp[index];
    setExpanded(expandedTemp);
  };

  return (
    <>
      <div className={styles.listContainer}>
        {gameUrl && (
          <>
            <div
              className={classNames(styles.providerWrapper, 'mobile-hidden')}
            >
              <p className={styles.provider}>Currently Playing</p>
            </div>
            <div className={styles.currentGame} ref={currentGameRef}>
              <iframe src={gameUrl} title="Current Game" allowFullScreen />
            </div>
          </>
        )}
        {gameList.map((providerGames: any, index: number) => {
          if (providerGames.games.length === 0) return <></>;
          return (
            <div
              key={index}
              className={classNames(
                styles.container,
                gameUrl && 'mobile-hidden'
              )}
            >
              {providerGames.provider && providerGames.games?.length > 0 && (
                <div
                  className={styles.providerWrapper}
                  onClick={() => changeExpanded(index)}
                >
                  <p className={styles.provider}>{providerGames.provider}</p>
                  {!expanded[index] ? (
                    <div
                      className={classNames(styles.expandIcon, styles.icons)}
                    />
                  ) : (
                    <div
                      className={classNames(styles.sizeDownIcon, styles.icons)}
                    />
                  )}
                </div>
              )}
              <div
                className={classNames(
                  styles.gameListContainer,
                  !expanded[index] ? ['mobile-hidden', 'desktop-hidden'] : ''
                )}
              >
                {providerGames.games.map((game: CasinoGame) => {
                  return (
                    <CasinoGameListItem
                      game={game}
                      key={game.uuid}
                      setGameUrl={setGameUrl}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {isMobile && (
        <div className={styles.popularGamesWrapper}>
          <button>
            {t('popular_games_label')}
            <div className={classNames('mobile-css-icons', styles.arrowIcon)} />
          </button>
        </div>
      )}
    </>
  );
};
