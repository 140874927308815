import { useTranslation } from 'react-i18next';
import styles from './Menu.module.css';
import { useLocation } from 'react-router-dom';

export const Menu = () => {
  const { t } = useTranslation();
  const url = useLocation().pathname;

  return (
    <div className={styles.menuWrapper}>
      <ul>
        <li className={styles.menuItem}>
          <a href="/" className={url === '/' ? styles.activeMenuitem : ''}>
            {t('home_label')}
          </a>
        </li>
        <li className={styles.menuItem}>
          <a
            href="/casino-games"
            className={url === '/casino-games' ? styles.activeMenuitem : ''}
          >
            {t('casino_games_label')}
          </a>
        </li>
        <li className={styles.menuItem}>
          <a
            href="/live-casino"
            className={url === '/live-casino' ? styles.activeMenuitem : ''}
          >
            {t('live_casino_label')}
          </a>
        </li>
        <li className={styles.menuItem}>
          <a
            href="/sports-betting"
            className={url === '/sports-betting' ? styles.activeMenuitem : ''}
          >
            {t('sports_betting_label')}
          </a>
        </li>
        <li className={styles.menuItem}>
          <a
            href="/live-betting"
            className={url === '/live-betting' ? styles.activeMenuitem : ''}
          >
            {t('live_betting_label')}
          </a>
        </li>
      </ul>
    </div>
  );
};
