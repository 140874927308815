import { MobileLayout } from 'components/mobile/MobileLayout/MobileLayout';
import styles from '../styles/pages/LanguageSelection.module.css';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLocales } from 'services/localesService';
import classNames from 'classnames';
import { useState } from 'react';

export const LanguageSelection = () => {
  const { t, i18n } = useTranslation();
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const locales = getLocales();

  return (
    <>
      {!isMobile && <Navigate to="/not-found" />}
      {isMobile && (
        <MobileLayout title={t('language_selection_label')}>
          <div className={styles.wrapper}>
            {locales.map((lang) => (
              <div
                className={classNames(
                  styles.languageOption,
                  selectedLanguage === lang.code ? styles.selectedLanguage : ''
                )}
                key={lang.code}
                onClick={() => setSelectedLanguage(lang.code)}
              >
                <img src={`/photos/icons/${lang.code}.png`} alt="Language" />
                <span className="sans-serif-design">{lang.fullName}</span>
              </div>
            ))}
            <div className={styles.buttonWrapper}>
              <button
                className="sans-serif-design"
                onClick={(e) => {
                  e.preventDefault();
                  i18n.changeLanguage(selectedLanguage);
                  localStorage.setItem('lang', selectedLanguage);
                  navigate('/');
                }}
              >
                {t('change_language_label')}
                {isMobile && (
                  <div
                    className={classNames('checkmark', 'mobile-css-icons')}
                  ></div>
                )}
              </button>
            </div>
          </div>
        </MobileLayout>
      )}
    </>
  );
};
