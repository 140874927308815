import styles from './SubHeader.module.css';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'context/userContext';
import { LoginForm } from 'components/LoginForm/LoginForm';
import { AccountInfo } from 'components/AccountInfo/AccountInfo';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const SubHeader = () => {
  const { loggedUser } = useContext(UserContext);
  const [balanceChanged, setBalanceChanged] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setBalanceChanged(!balanceChanged);
  }, [loggedUser?.balance]);

  return (
    <div className={styles.subHeaderWrapper}>
      <div className={styles.logoWrapper} onClick={() => navigate('/')}>
        <img src="/photos/logo.png" alt="logo" />
      </div>

      {loggedUser && (
        <>
          <div className={styles.iconsWrapper}>
            <IconButton
              destinationUrl="/my-bets"
              iconUrl="/photos/icons/desktop-my-bets.png"
              label={t('my_bets_label')}
            />
            <IconButton
              destinationUrl="/deposit"
              iconUrl="/photos/icons/desktop-deposit.png"
              label={t('deposit_label')}
            />
            <IconButton
              destinationUrl="/account-statement"
              iconUrl="/photos/icons/desktop-account-statement.png"
              label={t('account_statement_label')}
            />
            <IconButton
              destinationUrl="/account-settings"
              iconUrl="/photos/icons/desktop-account-settings.png"
              label={t('account_settings_label')}
            />
            <IconButton
              destinationUrl="/gaming-history"
              iconUrl="/photos/icons/desktop-gaming-history.png"
              label={t('gaming_history_label')}
            />
          </div>
          <AccountInfo user={loggedUser} />
        </>
      )}
      {!loggedUser && <LoginForm />}
    </div>
  );
};

interface IconButtonProps {
  destinationUrl: string;
  iconUrl: string;
  label: string;
}

const IconButton = ({ destinationUrl, iconUrl, label }: IconButtonProps) => {
  return (
    <a className={styles.iconButton} href={destinationUrl}>
      <img src={iconUrl} alt={label} />
      <label>{label}</label>
    </a>
  );
};
