import {
  MobileMenuItem,
  MobileMenuItemProps,
} from '../MobileMenuItem/MobileMenuItem';
import styles from './MobileMenu.module.css';

interface Props {
  menuItems: MobileMenuItemProps[];
}

export const MobileMenu = ({ menuItems }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {menuItems &&
          menuItems.length > 0 &&
          menuItems.map((item, index) => (
            <MobileMenuItem
              iconUrl={item.iconUrl}
              label={item.label}
              destinationUrl={item.destinationUrl}
              onClickAction={item.onClickAction}
              key={index}
            />
          ))}
      </div>
    </div>
  );
};
