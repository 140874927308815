import { MobileMenu } from 'components/mobile/MobileMenu/MobileMenu';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { MobileLayout } from 'components/mobile/MobileLayout/MobileLayout';
import { useContext } from 'react';
import { UserContext } from 'context/userContext';
import { MobileMenuItemProps } from 'components/mobile/MobileMenuItem/MobileMenuItem';

export const Home = () => {
  const { isMobile } = useBreakpoint();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loggedUser, logout } = useContext(UserContext);

  const mobilemenuItems: MobileMenuItemProps[] = [
    {
      iconUrl: '/photos/icons/menu-icons/sports-betting.png',
      label: t('sports_betting_label'),
      destinationUrl: '/sports-betting',
    },
    {
      iconUrl: '/photos/icons/menu-icons/live-betting.png',
      label: t('live_betting_label'),
      destinationUrl: '/live-betting',
    },
    {
      iconUrl: '/photos/icons/menu-icons/highlights.png',
      label: t('highlights_label'),
      destinationUrl: '/highlights',
    },
    {
      iconUrl: '/photos/icons/menu-icons/live-casino.png',
      label: t('live_casino_label'),
      destinationUrl: '/live-casino',
    },
    {
      iconUrl: '/photos/icons/menu-icons/casino-games.png',
      label: t('casino_games_label'),
      destinationUrl: '/casino-games',
    },
    {
      iconUrl: '/photos/icons/menu-icons/evo-auto-games.png',
      label: t('evo_auto_games_label'),
      destinationUrl: '/evo-auto-games',
    },
    {
      iconUrl: '/photos/icons/menu-icons/results.png',
      label: t('results_label'),
      destinationUrl: '/results',
    },
    {
      iconUrl: '/photos/icons/menu-icons/search.png',
      label: t('search_label'),
      destinationUrl: '/search',
    },
    {
      iconUrl: '/photos/icons/menu-icons/contact.png',
      label: t('contact_label'),
      destinationUrl: '/contact',
    },
    {
      iconUrl: '/photos/icons/menu-icons/language.png',
      label: t('language_label'),
      destinationUrl: '/language',
    },
    {
      iconUrl: '/photos/icons/menu-icons/other.png',
      label: t('other_label'),
      destinationUrl: '/other',
    },
    {
      iconUrl: '/photos/icons/menu-icons/login.png',
      label: t('login_label'),
      destinationUrl: '/login',
    },
  ];

  const logoutItem: MobileMenuItemProps = {
    iconUrl: '/photos/icons/menu-icons/logout.png',
    label: t('logout_label'),
    onClickAction: async () => {
      await logout();
      // navigate('/');
    },
  };

  const depositItem: MobileMenuItemProps = {
    iconUrl: '/photos/icons/menu-icons/deposit.png',
    label: t('deposit_label'),
    onClickAction: () => navigate('/deposit'),
  };

  const addLoggedUserIcons = () => {
    return [
      ...mobilemenuItems.slice(0, 6),
      depositItem, // Deposit Item to index 6
      ...mobilemenuItems.slice(6, mobilemenuItems.length - 1),
      logoutItem, // Logout at the end
    ];
  };

  return (
    <>
      {!isMobile && (
        <div className="mobile-hidden">
          <Navigate to="/casino-games" />
        </div>
      )}
      {isMobile && (
        <MobileLayout hideBack>
          <MobileMenu
            menuItems={!loggedUser ? mobilemenuItems : addLoggedUserIcons()}
          />
        </MobileLayout>
      )}
    </>
  );
};
