import styles from './MobileMenuItem.module.css';

export interface MobileMenuItemProps {
  iconUrl: string;
  label: string;
  destinationUrl?: string;
  onClickAction?: () => void;
}
export const MobileMenuItem = ({
  iconUrl,
  label,
  destinationUrl = '',
  onClickAction = () => {},
}: MobileMenuItemProps) => {
  if (destinationUrl) {
    return (
      <a href={destinationUrl} className={styles.wrapper}>
        <img src={iconUrl} alt={label} />
        <label>{label}</label>
      </a>
    );
  } else {
    return (
      <button
        className={styles.wrapper}
        onClick={() => {
          onClickAction();
        }}
      >
        <img src={iconUrl} alt={label} />
        <label>{label}</label>
      </button>
    );
  }
};
