import axios from 'axios';
import { CasinoGame } from 'types/CasinoGame';

export const getAllGamesAndProviders = async (): Promise<CasinoGame[]> => {
  const baseUrl: string = process.env.REACT_APP_GAME_API_BACKEND_API ?? '';

  const response = await axios.get(`${baseUrl}/get_all_games_and_providers`);

  return response.data;
};

export const getGameUrl = async (
  gameId: string,
  playerId: number,
  playerUsername: string
): Promise<string> => {
  const baseUrl: string = process.env.REACT_APP_GAME_API_BACKEND_API ?? '';

  const response = await axios.post(`${baseUrl}/init-demo`, {
    game_uuid: gameId,
    player_id: playerId,
    player_name: playerUsername,
  });

  return response.data.url as string;
};
