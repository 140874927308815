import axios from 'axios';
import { UserInfo } from 'types/UserInfo';

export const loginApi = async (
  username: string,
  password: string
): Promise<UserInfo | null> => {
  const baseUrl = process.env.REACT_APP_GAME_API_BACKEND_API ?? '';

  try {
    const response = await axios.post(`${baseUrl}/login`, {
      username,
      password,
    });
    return response.data;
  } catch {
    return null;
  }
};

export const logoutApi = async (loggedUser: UserInfo): Promise<boolean> => {
  const baseUrl = process.env.REACT_APP_GAME_API_BACKEND_API ?? '';

  try {
    const response = await axios.post(
      `${baseUrl}/logout`,
      {
        refresh_token: loggedUser.refresh_token,
      },
      {
        headers: {
          Authorization: `Bearer ${loggedUser.access_token}`,
        },
      }
    );
    console.log(response);
    return true;
  } catch (e: any) {
    console.log(e);
    alert(e.cause);
    return false;
  }
};

export const changePassword = async (
  old_password: string,
  new_password: string,
  loggedUser: UserInfo
): Promise<any> => {
  const baseUrl = process.env.REACT_APP_GAME_API_BACKEND_API ?? '';

  try {
    await axios.post(
      `${baseUrl}/change_password`,
      {
        old_password,
        new_password,
        username: loggedUser.username,
      },
      {
        headers: {
          Authorization: `Bearer ${loggedUser.access_token}`,
        },
      }
    );
    return true;
  } catch (e) {
    return false;
  }
};

export const accessTokenRefresh = async (
  loggedUser: UserInfo
): Promise<any> => {
  const baseUrl = process.env.REACT_APP_GAME_API_BACKEND_API ?? '';
  console.log(loggedUser);
  try {
    const response = await axios.post(
      `${baseUrl}/token/refresh`,
      {
        refresh: loggedUser.refresh_token,
      },
      {
        headers: {
          Authorization: `Bearer ${loggedUser.access_token}`,
        },
      }
    );
    return response.data.access;
  } catch (e) {
    return null;
  }
};
