import { UserInfo } from 'types/UserInfo';
import styles from './AccountInfo.module.css';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { UserContext } from 'context/userContext';

interface Props {
  user: UserInfo;
}

export const AccountInfo = ({ user }: Props) => {
  const { t } = useTranslation();
  const { logout } = useContext(UserContext);

  return (
    <div className={styles.accountInfoWrapper}>
      <span>
        {t('user_label')}:{' '}
        <span className={styles.userFieldValue}>
          {!user.first_name && !user.last_name
            ? `${user.username}`
            : `${user.first_name ?? ''}${user.first_name ? ' ' : ''}${user.last_name ?? ''}`}
        </span>
      </span>
      <span>
        {t('balance')}:{' '}
        <span className={styles.userFieldValue}>{`${user.balance}`}</span>
      </span>
      <button
        className={styles.logoutButton}
        onClick={async () => {
          await logout();
        }}
      >
        {t('logout_label')}
      </button>
    </div>
  );
};
