import { CasinoGame } from 'types/CasinoGame';

export const filterGamesByProviders = (
  allGames: CasinoGame[]
): { provider: string; games: CasinoGame[] }[] => {
  if (!allGames || !Array.isArray(allGames)) return [];

  const uniqueProviders: string[] = [];
  allGames.forEach((game) => {
    if (uniqueProviders.indexOf(game.provider) === -1) {
      uniqueProviders.push(game.provider);
    }
  });

  const filteredGames: any = [];

  uniqueProviders.forEach((provider) => {
    filteredGames.push({
      provider,
      games: allGames.filter((game) => game.provider === provider),
    });
  });

  return filteredGames;
};
