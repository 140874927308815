import { toast } from 'react-toastify';
import styles from './LoginForm.module.css';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { UserContext } from 'context/userContext';
import { useNavigate } from 'react-router-dom';

export const LoginForm = () => {
  const { register, handleSubmit, reset } = useForm();
  const { t } = useTranslation();
  const { login } = useContext(UserContext);
  const navigate = useNavigate();

  const formSubmitHandler = async (formData: any) => {
    const userInfo = await login(formData.username, formData.password);
    if (formData.username && formData.password && userInfo) {
      if (!userInfo.has_logged_in_before) {
        navigate('/change-password');
      }
    } else {
      toast(t('wrong_credentials'));
      reset();
    }
  };

  return (
    <div className={styles.loginWrapper}>
      <form onSubmit={handleSubmit(formSubmitHandler)}>
        <input
          type="text"
          placeholder={t('username')}
          {...register('username')}
        />
        <input
          type="password"
          placeholder="********"
          {...register('password')}
        />
        <input
          type="submit"
          className={styles.submitButton}
          value={t('login_label')}
        />
      </form>
      <div className={styles.forgotCredentialWrapper}>
        <a href="/">{t('forgot_username')}</a>
        <a href="/">{t('forgot_password')}</a>
      </div>
    </div>
  );
};
