import { useContext, useEffect, useState } from 'react';
import styles from './Header.module.css';
import { useTranslation } from 'react-i18next';
import { getLocales } from 'services/localesService';
import { UserContext } from 'context/userContext';
import classNames from 'classnames';

export const Header = () => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [flagPath, setFlagPath] = useState<string>('/photos/icons/en.png');
  const { loggedUser } = useContext(UserContext);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const selectedLang = localStorage.getItem('lang');
    if (selectedLang && selectedLang !== 'en') {
      setFlagPath(`/photos/icons/${selectedLang}.png`);
    }
  }, []);

  useEffect(() => {
    let timer = setInterval(() => setCurrentTime(new Date()), 1000);

    return () => clearInterval(timer);
  });

  const getTimeZoneString = (time: Date): string => {
    const offsetInHours: string = Intl.NumberFormat('sr-RS', {
      signDisplay: 'exceptZero',
    }).format(time.getTimezoneOffset() / -60);

    return `GMT ${offsetInHours}`;
  };
  return (
    <div className={styles.header}>
      <div className={styles.headerContent}>
        <img src="/photos/icons/header-clock.png" alt="Clock" />
        <span>{currentTime.toLocaleTimeString('sr-RS')}</span>
        <span>{getTimeZoneString(currentTime)}</span>
        {loggedUser && (
          <div className={styles.dateTimeInfoWrapper}>
            <span className={styles.loginDateInfo}>
              {t('last_login_label')}: {loggedUser.last_login}
            </span>
            <span className={styles.loginDateInfo}>
              {t('logged_in_from_label')}: {loggedUser.logged_in_from}
            </span>
          </div>
        )}
        <div
          className={classNames(
            styles.languageSelectWrapper,
            !loggedUser && styles.userLoggedIn
          )}
        >
          <img src={flagPath} alt="Flag" />
          <select
            className={styles.languageSelect}
            value={i18n.language}
            onChange={(e) => {
              const newLang = e.target.value;
              setFlagPath(`/photos/icons/${newLang}.png`);
              localStorage.setItem('lang', newLang);
              i18n.changeLanguage(newLang);
            }}
          >
            {getLocales().map((locale) => (
              <option value={locale.code} key={locale.code}>
                {locale.fullName}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
