export const TOAST_ERROR_STYLE = {
  color: '#d8000c',
  backgroundColor: '#ffbaba',
};

export const PASSWORD_COMPLEXITY_REGEX =
  /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]+$/g;

export const TOAST_AUTO_CLOSE_TIME = 1_500; // 1.5 second

export const WEB_SOCKET_PING_INTERVAL = 12_000; //12 seconds

export const ACCESS_TOKEN_REFRESH_INTERVAL = 240_000; // 4 minutes

export const WEB_SOCKET_PING_MESSAGE = JSON.stringify({
  message: 'Hello, I am still here.',
});

export const ROUTE_TITLE_MAP = new Map<String, String>([
  ['/casino-games', 'casino_games_label'],
  ['/', 'home_label'],
  ['/sports-betting', 'sports_betting_label'],
  ['/live-casino', 'live_casino_label'],
  ['/live-betting', 'live_betting_label'],
  ['/login', 'login_label'],
  ['/highlights', 'highlights_label'],
  ['/evo-auto-games', 'evo_auto_games_label'],
  ['/results', 'results_label'],
  ['/search', 'search_label'],
  ['/contact', 'contact_label'],
  ['/language', 'language_label'],
  ['/other', 'other_label'],
  ['/slip', 'slip_label'],
  ['/deposit', 'deposit_label'],
  ['/account-settings', 'account_settings_label'],
  ['/change-password', 'change_password_label'],
]);

export const WORK_IN_PROGRESS_PAGES = [
  '/responsible-gaming',
  '/sports-betting',
  '/live-betting',
  '/live-casino',
  '/highlights',
  '/evo-auto-games',
  '/results',
  '/search',
  '/contact',
  '/other',
  '/my-bets',
  '/deposit',
  '/account-statement',
  '/gaming-history',
  '/about-us',
  '/terms-and-conditions',
  '/privacy-policy',
  '/anti-money-laundering',
  '/responsible-gaming',
];
