import { Layout } from 'components/Layout/Layout';
import { UserContext } from 'context/userContext';
import { useContext, useEffect, useState } from 'react';
import styles from '../styles/pages/ChangePassword.module.css';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { MobileLayout } from 'components/mobile/MobileLayout/MobileLayout';
import { toast } from 'react-toastify';
import { changePassword } from 'services/authService';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { PASSWORD_COMPLEXITY_REGEX } from 'config/constants';
import Modal from 'react-modal';

export const ChangePassword = () => {
  const { loggedUser } = useContext(UserContext);
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();
  const { handleSubmit, register, reset } = useForm();
  const [isModalopen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!loggedUser) {
      navigate('/login');
    }
  }, []);

  const formSubmitHandler = async (data: any) => {
    if (data.newPassword.length < 8) {
      toast(t('short_password_message'));
      reset();
    } else if (data.newPassword !== data.newPassword2) {
      toast(t('password_missmatch_message'));
      reset();
    } else if (!(data.newPassword as string).match(PASSWORD_COMPLEXITY_REGEX)) {
      toast(t('password_complexity_message'));
      reset();
    } else {
      const result = await changePassword(
        data.currentPassword,
        data.newPassword,
        loggedUser
      );
      if (!result) {
        toast(t('wrong_old_password_message'));
        reset();
      } else {
        navigate('/');
      }
    }
  };

  const getFormJSX = () => {
    return (
      <form className={styles.form} onSubmit={handleSubmit(formSubmitHandler)}>
        <label>{t('current_password_label')}:</label>
        <input
          type="password"
          {...register('currentPassword', {
            required: 'This field is required',
          })}
        />

        <div className={styles.newPassword}>
          <label>{t('new_password_label')}:</label>
          {isMobile && (
            <img
              className={styles.mobileInfo}
              onClick={() => setIsModalOpen(true)}
              src="/photos/icons/info.png"
              alt="Info"
            />
          )}
        </div>
        <input
          type="password"
          {...register('newPassword', { required: 'This field is required' })}
        />
        <label>{t('repeat_password_label')}:</label>
        <input
          type="password"
          {...register('newPassword2', { required: 'This field is required' })}
        />
        <button className={styles.submitButton}>
          {t('save_label')}{' '}
          {isMobile && (
            <div className={classNames('checkmark', 'mobile-css-icons')}></div>
          )}
        </button>
      </form>
    );
  };

  const getRequirementsJSX = () => {
    return (
      <div className={styles.requirementsWrapper}>
        <p>{t('password_requirements_title')}:</p>
        <ul>
          <li>{t('password_requirement_1_label')}</li>
          <li>{t('password_requirement_2_label')}</li>
        </ul>
        <p>{t('tips_title')}:</p>
        <ul>
          <li>{t('tip_1_label')}</li>
          <li>{t('tip_2_label')}</li>
          <li>{t('tip_3_label')}</li>
        </ul>
      </div>
    );
  };

  return (
    <>
      {!isMobile ? (
        <Layout
          hideSubheader={!loggedUser.has_logged_in_before}
          hideMenu={!loggedUser.has_logged_in_before}
        >
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <p className={styles.title}>
                {loggedUser.has_logged_in_before
                  ? t('change_password_label')
                  : t('initial_password_change_text')}
              </p>
              <div className={styles.infoWrapper}>
                <img src="/photos/icons/info.png" alt="Info" />
                {getRequirementsJSX()}
              </div>
              {getFormJSX()}
            </div>
          </div>
        </Layout>
      ) : (
        <MobileLayout title={t('change_password_label')}>
          {getFormJSX()}
          <Modal
            isOpen={isModalopen}
            onRequestClose={() => setIsModalOpen(false)}
            contentLabel={t('password_requirements_title')}
            className={styles.modal}
          >
            <div className={styles.modalWrapper}>
              <div
                className={styles.closeButtonWrapper}
                onClick={() => setIsModalOpen(false)}
              >
                <div
                  className={classNames('close-icon', styles.closeIcon)}
                ></div>
              </div>
              {getRequirementsJSX()}
            </div>
          </Modal>
        </MobileLayout>
      )}
    </>
  );
};
