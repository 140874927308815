import { CasinoGameList } from 'components/CasinoGameList/CasinoGameList';
import { Layout } from 'components/Layout/Layout';
import { Loader } from 'components/Loader/Loader';
import { MobileLayout } from 'components/mobile/MobileLayout/MobileLayout';
import { filterGamesByProviders } from 'helpers/gameHelper';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllGamesAndProviders } from 'services/gameApiService';
import { CasinoGame } from 'types/CasinoGame';
import useAsyncEffect from 'use-async-effect';
import styles from '../styles/pages/CasinoGames.module.css';

interface ProviderGames {
  provider: string;
  games: CasinoGame[];
}

export const CasinoGames = () => {
  const { isMobile } = useBreakpoint();
  const [allGames, setAllGames] = useState<ProviderGames[]>([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [currentSearch, setCurrentSearch] = useState('');
  const [searchResults, setSearchResults] = useState<ProviderGames[]>([]);

  useAsyncEffect(async () => {
    try {
      const gamesResponse = await getAllGamesAndProviders();
      let gamesByProviders = filterGamesByProviders(gamesResponse);
      setIsLoading(false);
      setAllGames(gamesByProviders);
    } catch {
      setIsLoading(false);
    }
  }, []);

  const handleSearch = (event: any) => {
    const currentSearch = event.target.value;
    setCurrentSearch(currentSearch);

    const searchResults: ProviderGames[] = [];

    allGames?.forEach((x) => {
      searchResults.push({
        provider: x.provider,
        games: x.games.filter((game) =>
          game.name
            .toLocaleLowerCase()
            .includes(currentSearch.toLocaleLowerCase())
        ),
      });
    });
    setSearchResults(searchResults);
  };

  const showAllGames = () => {
    return <>{allGames && <CasinoGameList gameList={allGames} />}</>;
  };

  const showSearchResults = () => {
    return <>{searchResults && <CasinoGameList gameList={searchResults} />}</>;
  };

  return (
    <>
      {isMobile && (
        <MobileLayout title={t('all_games_label')}>
          <div className={styles.searchWrapper}>
            <input
              type="text"
              className={styles.searchInput}
              onChange={handleSearch}
              value={currentSearch}
            />
            {currentSearch && (
              <div
                className={styles.cancelSearchIcon}
                onClick={() => setCurrentSearch('')}
              />
            )}
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {!currentSearch && showAllGames()}
              {currentSearch && showSearchResults()}
            </>
          )}
        </MobileLayout>
      )}
      {!isMobile && (
        <Layout>
          {isLoading ? (
            <Loader spinnerColor="white" />
          ) : (
            <>
              {!currentSearch && showAllGames()}
              {currentSearch && showSearchResults()}
            </>
          )}
        </Layout>
      )}
    </>
  );
};
