import { useBreakpoint } from 'hooks/useBreakpoint';
import styles from '../styles/pages/MobileLogin.module.css';
import { Navigate, useNavigate } from 'react-router-dom';
import { MobileLayout } from 'components/mobile/MobileLayout/MobileLayout';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useContext, useEffect } from 'react';
import { UserContext } from 'context/userContext';
import { toast } from 'react-toastify';
import classNames from 'classnames';

export const MobileLogin = () => {
  const { isMobile } = useBreakpoint();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { t } = useTranslation();
  const { login, loggedUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedUser) {
      navigate('/');
    }
  }, []);

  if (!isMobile) {
    return <Navigate to="/" />;
  }

  const formSubmitHandler = async (formData: any, event: any) => {
    event.preventDefault();
    const userInfo = await login(formData.username, formData.password);
    if (formData.username && formData.password && userInfo) {
      if (!userInfo.has_logged_in_before) {
        navigate('/change-password');
      } else {
        navigate('/');
      }
    } else {
      toast(t('wrong_credentials'));
      reset();
    }
  };

  return (
    <MobileLayout>
      <div className={styles.titleWrapper}>
        <span className={styles.title}>
          <img src="/photos/icons/menu-icons/login.png" alt="login-icon" />
          Login
        </span>
      </div>
      <form onSubmit={handleSubmit(formSubmitHandler)}>
        <span className={styles.labelInputGroup}>
          <label>{t('username')}: </label>
          <input
            type="text"
            className={errors?.username && styles.errorInput}
            {...register('username', { required: 'required' })}
          ></input>
        </span>
        <span className={styles.labelInputGroup}>
          <label>{t('password')}: </label>
          <input
            type="password"
            className={errors?.password && styles.errorInput}
            {...register('password', { required: 'required' })}
          ></input>
        </span>
        <span className={styles.labelInputGroup}>
          <button className={styles.submit}>
            {t('login_label')}
            <div className={classNames('keylock', 'mobile-css-icons')}></div>
          </button>
        </span>
      </form>
    </MobileLayout>
  );
};
