import { CasinoGame } from 'types/CasinoGame';
import styles from './CasinoGameListItem.module.css';
import { Dispatch, SetStateAction, useContext } from 'react';
import { UserContext } from 'context/userContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getGameUrl } from 'services/gameApiService';

interface Props {
  game: CasinoGame;
  setGameUrl: Dispatch<SetStateAction<string>>;
}

export const CasinoGameListItem = ({ game, setGameUrl }: Props) => {
  const { t } = useTranslation();
  const { loggedUser } = useContext(UserContext);

  const handleClickOnGame = async (game: CasinoGame) => {
    if (loggedUser == null) {
      toast(t('must_be_logged_in'));
    } else {
      const gameUrl = await getGameUrl(
        game.uuid,
        loggedUser.id,
        loggedUser.username
      );
      setGameUrl(gameUrl);
    }
  };

  return (
    <div className={styles.gameWrapper} onClick={() => handleClickOnGame(game)}>
      <img src={game.image} alt={`${game.name}`} />
      <p>{game.name}</p>
      <div className={styles.playButton} />
    </div>
  );
};
