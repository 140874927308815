import styles from './Loader.module.css';

interface Props {
  spinnerColor?: 'white' | 'default';
}

export const Loader = ({ spinnerColor = 'default' }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.loader} data-spinner-color={spinnerColor}></div>
    </div>
  );
};
