import { ReactNode, useContext, useEffect, useState } from 'react';
import styles from './MobileLayout.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_TITLE_MAP } from 'config/constants';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { UserContext } from 'context/userContext';

interface Props {
  children?: ReactNode;
  title?: string;
  hideBack?: boolean;
}

export const MobileLayout = ({
  children = <></>,
  title,
  hideBack = false,
}: Props) => {
  const url = useLocation().pathname;
  const { loggedUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [currentBalance, setCurrentBalance] = useState(
    loggedUser?.balance ?? 0
  );

  const sportsBetting = '0.0'; // Should be replaced with state!
  const slipNumber = '0'; // Should be replaced with state!

  const pageTitle = ROUTE_TITLE_MAP.get(url);

  useEffect(() => {
    setCurrentBalance(loggedUser?.balance);
  }, [loggedUser?.balance]);

  return (
    <>
      <div className={styles.header}>
        {!hideBack && (
          <div className={styles.headerIcon} onClick={() => navigate(-1)}>
            <img src="/photos/icons/chevron-left.svg" alt="back" />
          </div>
        )}
        <span className={styles.currentPage}>
          {pageTitle ? t(pageTitle as string) : t('page_not_found_label')}
        </span>
        <div className={styles.headerIcon}>
          <img src="/photos/icons/panel-home.png" alt="Home" />
        </div>
      </div>
      <div className={styles.content}>
        {title && <p className={styles.title}>{title}</p>}
        {children}
      </div>
      <div
        className={classNames(styles.footer, {
          [styles['loggedUser']]: !!loggedUser,
        })}
      >
        <a
          className={classNames(
            styles.footerItem,
            url === '/' ? styles.footerItemActive : ''
          )}
          href="/"
        >
          <img src="/photos/icons/home.png" alt="Home" />
        </a>
        {!loggedUser && (
          <a
            className={classNames(
              styles.footerItem,
              url === '/login' ? styles.footerItemActive : ''
            )}
            href="/login"
          >
            <img src="/photos/icons/menu-icons/login.png" alt="Login" />
            <p>{t('login_label')}</p>
          </a>
        )}
        {loggedUser && (
          <>
            <a
              className={classNames(
                styles.footerItem,
                url === '/account-settings' ? styles.footerItemActive : ''
              )}
              href="/account-settings"
            >
              <div className={styles.valueWrapper}>{currentBalance}</div>
              <img
                src="/photos/icons/menu-icons/account-settings.png"
                alt="Account Settings"
              />
              <p>{t('account_settings_label')}</p>
            </a>
            <a
              className={classNames(
                styles.footerItem,
                url === '/my-bets' ? styles.footerItemActive : ''
              )}
              href="/my-bets"
            >
              <img src="/photos/icons/menu-icons/my-bets.png" alt="My Bets" />
              <p>{t('my_bets_label')}</p>
            </a>
          </>
        )}
        <a
          className={classNames(
            styles.footerItem,
            url === '/sports-betting' ? styles.footerItemActive : ''
          )}
          href="/sports-betting"
        >
          {loggedUser && (
            <div
              className={styles.valueWrapper}
              data-right={true}
              data-color="blue"
            >
              {sportsBetting}
            </div>
          )}
          <img
            src="/photos/icons/menu-icons/sports-betting.png"
            alt="Sports Betting"
          />
          <p>{t('sports_betting_label')}</p>
        </a>
        <a
          className={classNames(
            styles.footerItem,
            url === '/slip' ? styles.footerItemActive : ''
          )}
          href="/slip"
        >
          {loggedUser && (
            <div className={styles.valueWrapper} data-right={true}>
              {slipNumber}
            </div>
          )}
          <img src="/photos/icons/menu-icons/slip.png" alt="Slip" />
          <p>{t('slip_label')}</p>
        </a>
      </div>
    </>
  );
};
