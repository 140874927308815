import './styles/main.css';
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import translationSR from './locales/sr/translation.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { CasinoGames } from 'pages/CasinoGames';
import { WorkInProgress } from 'pages/WorkInProgress';
import UserContext from 'context/userContext';
import { NotFound } from 'pages/error-pages/NotFound';
import { Home } from 'pages/Home';
import { MobileLogin } from 'pages/MobileLogin';
import { LanguageSelection } from 'pages/LanguageSelection';
import { TOAST_ERROR_STYLE, WORK_IN_PROGRESS_PAGES } from 'config/constants';
import { ChangePassword } from 'pages/ChangePassword';
import { ToastContainer } from 'react-toastify';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  sr: {
    translation: translationSR,
  },
};

const lang = localStorage.getItem('lang') ?? 'en';

i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  fallbackLng: lang,
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  return (
    <>
      <ToastContainer
        limit={1}
        pauseOnHover={false}
        autoClose={1500}
        hideProgressBar={true}
        toastStyle={TOAST_ERROR_STYLE}
      />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <UserContext>
                <Home />
              </UserContext>
            }
          />
          <Route
            path="/casino-games"
            element={
              <UserContext>
                <CasinoGames />
              </UserContext>
            }
          />
          <Route
            path="/login"
            element={
              <UserContext>
                <MobileLogin />
              </UserContext>
            }
          />
          <Route
            path="/language"
            element={
              <UserContext>
                <LanguageSelection />
              </UserContext>
            }
          />
          <Route
            path="/change-password"
            element={
              <UserContext>
                <ChangePassword />
              </UserContext>
            }
          />
          <Route
            path="/account-settings"
            element={
              <UserContext>
                <ChangePassword />
              </UserContext>
            }
          />
          {WORK_IN_PROGRESS_PAGES.map((route, index) => (
            <Route
              key={index}
              path={route}
              element={
                <UserContext>
                  <WorkInProgress />
                </UserContext>
              }
            />
          ))}
          <Route
            path="*"
            element={
              <UserContext>
                <NotFound />
              </UserContext>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
