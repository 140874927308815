import { breakpoints } from 'config/breakpoints';
import { useEffect, useMemo, useState } from 'react';

type Breakpoint = 'mobile' | 'desktop';

export const useBreakpoint = (): { isMobile: boolean; isDesktop: boolean } => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>('mobile');
  const [windowSize, setWindowSize] = useState<
    Partial<{ height: number; width: number }>
  >({ height: undefined, width: undefined });

  const device = useMemo(() => {
    switch (breakpoint) {
      case 'mobile':
        return { isMobile: true, isDesktop: false };
      case 'desktop':
        return { isMobile: false, isDesktop: true };
    }
  }, [breakpoint]);

  const handleResize = () =>
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    if (windowSize.width) {
      if (windowSize.width < 1050) {
        setBreakpoint(breakpoints[0] as Breakpoint);
      } else {
        setBreakpoint(breakpoints[1050] as Breakpoint);
      }
    }

    return window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);

  return device;
};
